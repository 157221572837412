import React from "react";
import { StaffPage, staffData } from "../Common jquery/webkitText";
import { handleError } from "../Common jquery/CommonJquery.js";
import { useState, useEffect } from "react";
import {
  server_post_data,
  guest_faculty,
} from "../ServiceConnection/serviceconnection.js";

const GuestFaculti = () => {
  const [GuestTabledata, setfaculty] = useState();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);

  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(guest_faculty, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message.title_name);
        } else {
          setfaculty(Response.data.message);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  console.log(GuestTabledata);
  return (
    <div>
      <div className="table-responsive tbbbll">
        <table className="table table-hover">
          <thead className="thead">
            <tr>
              <th>{StaffPage.tableHead}</th>
              <th>{StaffPage.tableHead2}</th>
            </tr>
          </thead>
          <tbody>
            {!GuestTabledata
              ? []
              : GuestTabledata.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.qualification}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GuestFaculti;

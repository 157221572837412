import { useState, useRef, useEffect } from "react";
import Swiper from "swiper";
import "/node_modules/swiper/swiper";
import libimg1 from "../assets/lib-img1.jpg";
import libimg2 from "../assets/lib-img2.jpg";
import adminFecImg1 from "../assets/adminfes-1.jpg";
import adminFecImg2 from "../assets/adminfes-2.jpg";
import adminFecImg3 from "../assets/adminfes-3.jpg";
import adminFecImg4 from "../assets/adminfes-4.jpg";
import adminFecImg5 from "../assets/adminfes-5.jpg";
import adminFecImg6 from "../assets/adminfes-6.jpg";
import adminFecImg7 from "../assets/adminfes-7.jpg";
import adminFecImg8 from "../assets/adminfes-8.jpg";
import adminFecImg9 from "../assets/adminfes-9.jpg";
import adminFecImg10 from "../assets/adminfes-10.jpg";
import adminFecImg11 from "../assets/adminfes-11.jpg";
import complab1 from "../assets/comlab-img1.jpg";
import complab2 from "../assets/comlab-img2.jpg";
import LeftArrow from "../assets/leftArrow.svg";
import RightArrow from "../assets/rightArrow.svg";
import { IndexHomePage } from "../Common jquery/webkitText";

const IndexFacility = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const swiperRef = useRef(null);
  //faciite image change

  const imageUrls = [complab1, complab2];
  const imageUrls2 = [libimg1, libimg2];
  const imageUrls3 = [
    adminFecImg1,
    adminFecImg2,
    adminFecImg3,
    adminFecImg4,
    adminFecImg5,
    adminFecImg6,
    adminFecImg7,
    adminFecImg8,
    adminFecImg9,
    adminFecImg10,
    adminFecImg11,
  ];

  useEffect(() => {
    const facilitySliderInterval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 4000);

    return () => {
      clearInterval(facilitySliderInterval);
    };
  }, [imageUrls.length]);

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = new Swiper(swiperRef.current, {
        spaceBetween: 30,
        effect: "fade",
        loop: true,
        mousewheel: {
          invert: false,
        },
        pagination: {
          el: ".blog-slider__pagination",
          clickable: true,
        },
      });

      return () => {
        swiper.destroy();
      };
    }
  }, []);

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const goToSlide = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <div>
      <div className="blog-slider" ref={swiperRef}>
        <div className="blog-slider__wrp swiper-wrapper ">
          <div className="blog-slider__item swiper-slide BlogFaciliteSlider">
            <div className="blog-slider__img">
              <img src={imageUrls[imageIndex]} alt="" />
            </div>
            <div className="blog-slider__content">
              <span className="blog-slider__code">
                {IndexHomePage.ComputerLabTxt}
              </span>
              <div className="blog-slider__title">
                {IndexHomePage.ComputerContent}
              </div>
            </div>
          </div>
          <div className="blog-slider__item swiper-slide">
            <div className="blog-slider__img">
              <div className="blog-slider__img">
                <img src={imageUrls2[imageIndex]} alt="" />
              </div>
            </div>
            <div className="blog-slider__content">
              <span className="blog-slider__code">
                {IndexHomePage.LibraryText}
              </span>
              <div className="blog-slider__title">
                {IndexHomePage.LibraryContent}
              </div>
            </div>
          </div>
          <div className="blog-slider__item swiper-slide">
            <div className="blog-slider__img">
              <div className="blog-slider__img">
                <img src={imageUrls3[imageIndex]} alt="" />
              </div>
            </div>
            <div className="blog-slider__content">
              <span className="blog-slider__code">
                {IndexHomePage.TraningText}
              </span>
              <div className="blog-slider__title">
                {IndexHomePage.TraningContent}
              </div>
            </div>
          </div>
        </div>
        <div className="blog-slider__pagination">
          <span
            className={`blog-slider__pagination__dot ${
              imageIndex === currentImageIndex ? "active" : ""
            }`}
            onClick={() => goToSlide(imageIndex)}
          ></span>
        </div>
        <div className="ArowsFac">
          <div className="LeftArr">
            {" "}
            <button onClick={goToPrevSlide}>
              <img src={LeftArrow} alt=""></img>
            </button>
          </div>
          <div className="RightArr">
            {" "}
            <button onClick={goToNextSlide}>
              {" "}
              <img src={RightArrow} alt=""></img>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexFacility;

import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";

import FacbookIcon from "../assets/icons8-facebook.svg";
import LinkDinIcon from "../assets/icons8-linkedin.svg";
import intsaGramIcon from "../assets/instagram-167-svgrepo-com.svg";
import TwitterIcon from "../assets/icons8-twitterx.svg";
import WhatAppIcon from "../assets/icons8-whatsapp.svg";
import YoutubeIcon from "../assets/youtube-svgrepo-com.svg";
import KooIcon from "../assets/icons8-koo.svg";
import { ContactUspage } from "../Common jquery/webkitText";
import {
  handleEmailChange,
  handleNumbersChange,
  handleAphabetsChange,
  combiled_form_data,
  check_vaild_save,
  handleIaphabetnumberChange,
  handleSuccessSession,
  handleError,
} from "../Common jquery/CommonJquery";
import {
  server_post_data,
  insert_contact_data,
  get_all_social_links,
} from "../ServiceConnection/serviceconnection.js";
import { retrieveData } from "../LocalConnection/LocalConnection.js";

const ContactUs = () => {
  const retrievedAdminId = retrieveData("staff_id");
  const [focusedInput, setFocusedInput] = useState(null);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_social_links, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSocialLinks(Response.data.message[0]);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data);
      fd_from.append("admin_id", retrievedAdminId);

      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/contact_Us");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFocus = (e) => {
    setFocusedInput(e.target.name);
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setFocusedInput(null);
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid"></div>
      <div className="ContactUsContainer">
        <div className="ContactUsContent2">
          {" "}
          <span className="big-circle"></span>
          <img src="img/shape.png" className="square" alt="" />
          <div className="form">
            <div className="contact-info">
              <div className="info">
                <div className="information">
                  <p>{socialLinks.address}</p>
                </div>
                <div className="information">
                  <p>{socialLinks.email}</p>
                </div>
                <div className="information">
                  <p>0755-{socialLinks.contactno}</p>
                </div>
              </div>

              <div className="social-media">
                <p>{ContactUspage.contWithUStxt}</p>
                <div className="social-icons">
                  <a
                    href={socialLinks.Facebookname}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                    <div className="intsaGramIconCont">
                      <img src={FacbookIcon} alt="fb"></img>
                    </div>
                  </a>
                  <a
                    href={socialLinks.Instagramname}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                    <div className="intsaGramIconCont">
                      <img src={LinkDinIcon} alt="fb"></img>
                    </div>
                  </a>
                  <a
                    href={socialLinks.LinkedInname}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagramCont"></i>
                    <div className="intsaGramIconCont">
                      <img src={intsaGramIcon} alt="fb"></img>
                    </div>
                  </a>
                  <a
                    href={socialLinks.Twittername}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                    <div className="intsaGramIconCont">
                      <img src={TwitterIcon} alt="fb"></img>
                    </div>
                  </a>
                  <a
                    href={socialLinks.Youtubename}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-Youtube"></i>
                    <div className="intsaGramIconCont">
                      <img src={YoutubeIcon} alt="fb"></img>
                    </div>
                  </a>
                  <a
                    href={socialLinks.Whatsappname}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-WhatsApp"></i>
                    <div className="intsaGramIconCont">
                      <img src={WhatAppIcon} alt="fb"></img>
                    </div>
                  </a>
                  <a
                    href={socialLinks.kooname}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-Koo"></i>
                    <div className="intsaGramIconCont">
                      <img src={KooIcon} alt="fb"></img>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <form id="FOEMsubmit">
                <div className="ContUstitle">
                  {" "}
                  <h3>{ContactUspage.ContactUsText}</h3>
                </div>

                <div
                  className={`input-container ${
                    focusedInput === "name" ? "focus" : ""
                  }`}
                >
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="input form-control trio_mandatory "
                    minLength={3}
                    maxLength={20}
                    onInput={handleAphabetsChange}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <label>{ContactUspage.userNamee}</label>
                  <span>{ContactUspage.userNamee}</span>
                </div>
                <div
                  className={`input-container ${
                    focusedInput === "email" ? "focus" : ""
                  }`}
                >
                  <input
                    type="email"
                    id="useremail"
                    name="useremail"
                    className="input form-control trio_mandatory"
                    minLength={3}
                    maxLength={100}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onInput={handleEmailChange}
                    onBlur={handleBlur}
                  />
                  <label>{ContactUspage.UserEmail}</label>
                  <span>{ContactUspage.UserEmail}</span>
                </div>
                <div
                  className={`input-container ${
                    focusedInput === "phone" ? "focus" : ""
                  }`}
                >
                  <input
                    type="text"
                    id="Phone"
                    name="Phone"
                    className="input form-control trio_mandatory"
                    minLength={3}
                    maxLength={15}
                    onInput={handleNumbersChange}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label>{ContactUspage.UserPhn}</label>
                  <span>{ContactUspage.UserPhn}</span>
                </div>
                <div
                  className={`input-container textarea ${
                    focusedInput === "message" ? "focus" : ""
                  }`}
                >
                  <textarea
                    name="message"
                    id="message"
                    className="input form-control trio_mandatory"
                    onInput={handleIaphabetnumberChange}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  <label>{ContactUspage.UserMsg}</label>
                  <span>{ContactUspage.UserMsg}</span>
                </div>
                <div className="Subbtnn">
                  {" "}
                  <button
                    type="button"
                    value="Send"
                    className="btn"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "FOEMsubmit",
                        insert_contact_data
                      )
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;

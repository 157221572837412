import React from "react";
import facult1 from "../assets/facul-1.jpg";
import facult2 from "../assets/facul-2.jpg";
import facult3 from "../assets/facul-3.jpg";
import { StaffPage, staffData } from "../Common jquery/webkitText";
import { handleError } from "../Common jquery/CommonJquery.js";
import { useState, useEffect } from "react";
import {
  server_post_data,
  get_faculty_deatails,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";

const FacultiDeatailts = () => {
  const [getfaculty, setfaculty] = useState();
  const [staffDataimage_path, setstaffDataimage_path] = useState();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_faculty_deatails, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfaculty(Response.data.message);
          setstaffDataimage_path(Response.data.image_path);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
    console.log(getfaculty);
  };
  return (
    <div>
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>

      <div className="team-section">
        {!getfaculty
          ? []
          : getfaculty.map((faculty, index) => (
              <div className="team-member">
                <img src={APL_LINK+staffDataimage_path+faculty.Image_url} alt="img" />
                <h3>{faculty.name_faculty}</h3>
                <p className="role">{faculty.responsibility}</p>
                <p className="role-2">{faculty.education}</p>
              </div>
            ))}
        {/* <div className="team-member">
          <img src={facult2} alt="img" />
          <h3>{StaffPage.FacultiText2}</h3>
          <p className="role">{StaffPage.Facultirole2}</p>
          <p className="role-2">{StaffPage.Facultiqualifications2}</p>
        </div>
        <div className="team-member">
          <img src={facult3} alt="img" />
          <h3>{StaffPage.FacultiText3}</h3>
          <p className="role">{StaffPage.Facultirole3}</p>
          <p className="role-2">{StaffPage.Facultiqualifications3}</p>
        </div> */}
      </div>
    </div>
  );
};

export default FacultiDeatailts;

import React from "react";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { enIN } from "date-fns/locale";
import HomeIcon from "../assets/home-icon-silhouette-svgrepo-com.svg";
import LogoIcone from "../assets/logoPng.png";
import { HeaderPage, language_MAIN } from "../Common jquery/webkitText";
import FacbookIcon from "../assets/icons8-facebook.svg";
import LinkDinIcon from "../assets/icons8-linkedin.svg";
import intsaGramIcon from "../assets/instagram-167-svgrepo-com.svg";
import TwitterIcon from "../assets/icons8-twitterx.svg";
import WhatAppIcon from "../assets/icons8-whatsapp.svg";
import YoutubeIcon from "../assets/youtube-svgrepo-com.svg";
import KooIcon from "../assets/icons8-koo.svg";
import { handleError } from "../Common jquery/CommonJquery.js";
import {
  server_post_data,
  get_all_social_links,
} from "../ServiceConnection/serviceconnection.js";
import { Link } from "react-router-dom";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection.js";
const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [Totalhit, setTotalhit] = useState(0);
  const [socialLinks, setSocialLinks] = useState([]);
  const today = new Date();
  const formattedDate = format(today, "dd-MM-yyyy", { locale: enIN });
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    const today_datedddd = new Date();
    const year = today_datedddd.getFullYear();
    const month = String(today_datedddd.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today_datedddd.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const today_date = retrieveData("today_date");
    let store_data_file = 0;
    if (today_date == formattedDate) {
      store_data_file = 1;
    }
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("hit_data", store_data_file);
    fd.append("today_date", formattedDate);
    await server_post_data(get_all_social_links, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSocialLinks(Response.data.message.list_of_dicts[0]);
          setTotalhit(Response.data.message.data_count);
        }
        storeData("today_date", formattedDate);
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const clicktochnage = (hindi_english) => {
    let language = "english";
    try {
      const value = localStorage.getItem("hind_english");
      if (value !== null) {
        language = value;
      }
    } catch (error) {
      //error
    }

    if (language !== hindi_english) {
      localStorage.setItem("hind_english", hindi_english);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="row m-0">
        <div className={showLoaderAdmin ? "loading_website" : ""}></div>
        <div className="headerBar2">
          <div className="CallNumbs">
            <div className="social-media">
              <div className="social-icons">
                <a
                  href={socialLinks.Facebookname}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                  <div className="intsaGramIconCont">
                    <img src={FacbookIcon} alt="yt"></img>
                  </div>
                </a>
                <a
                  href={socialLinks.LinkedInname}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                  <div className="intsaGramIconCont">
                    <img src={LinkDinIcon} alt="yt"></img>
                  </div>
                </a>
                <a
                  href={socialLinks.Instagramname}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagramCont"></i>
                  <div className="intsaGramIconCont">
                    <img src={intsaGramIcon} alt="yt"></img>
                  </div>
                </a>
                <a
                  href={socialLinks.Twittername}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                  <div className="intsaGramIconCont">
                    <img src={TwitterIcon} alt="yt"></img>
                  </div>
                </a>
                <a
                  href={socialLinks.Youtubename}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-Youtube"></i>
                  <div className="intsaGramIconCont">
                    <img src={YoutubeIcon} alt="yt"></img>
                  </div>
                </a>
                <a
                  href={socialLinks.Whatsappname}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-WhatsApp"></i>
                  <div className="intsaGramIconCont">
                    <img src={WhatAppIcon} alt="yt"></img>
                  </div>
                </a>
                <a href={socialLinks.kooname} target="_blank" rel="noreferrer">
                  <i className="fab fa-Koo"></i>
                  <div className="intsaGramIconCont">
                    <img src={KooIcon} alt="yt"></img>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="English_hindibtn">
            {language_MAIN === "hindi" && (
              <div className="EnglishBtn">
                {" "}
                <button onClick={() => clicktochnage("english")}>
                  English
                </button>
              </div>
            )}

            {language_MAIN === "english" && (
              <div className="HindiBtn">
                {" "}
                <button onClick={() => clicktochnage("hindi")}>हिंदी</button>
              </div>
            )}
            <div className="DateCUrrent"> {formattedDate}</div>
          </div>

          <div className="LogO">
            <div className="LogOImg">
              <Link to="/">
                <img src={LogoIcone} alt="logo" />
              </Link>
            </div>
            <div className="contentTeXt">
              <h1>{HeaderPage.HeadingText}</h1>

              <label>{HeaderPage.HeadingText2}</label>
              <label>{HeaderPage.HeadingText3}</label>
            </div>
          </div>
        </div>

        <div className="heroSection">
          <div className="heroContent">
            <marquee
              className="marquText"
              behavior="scroll"
              direction="left"
              scrollamount="1"
            >
              <a href="www.google.com">{socialLinks.title}</a>
            </marquee>
          </div>
        </div>

        <div className="navbAr">
          <nav className="navbar navbar-expand-sm navbar-light bg-light">
            <div className="ToggleButtn">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div className="collapse navbar-collapse " id="navbarText">
              <ul className="d-flex navbar-nav h-100 w-100 text-md-center">
                <li className="nav-item flex-fill">
                  <Link to="/" className="nav-link HomeIcon">
                    <img src={HomeIcon} alt="homeIcon" />
                  </Link>
                </li>
                <li className="nav-item flex-fill dropdown">
                  <Link to="/about_us" className="nav-link">
                    {HeaderPage.aboutUsText}
                  </Link>
                  <div
                    className={`dropdown-menu   no-animation ${
                      isDropdownOpen ? "show" : ""
                    }`}
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    <Link to="/rti_page" className="dropdown-item programDrop">
                      {HeaderPage.aboutUsText2}
                    </Link>
                  </div>
                </li>
                <li className="nav-item flex-fill">
                  <Link to="/syllabus" className="nav-link">
                    {HeaderPage.SyllabusText}
                  </Link>
                </li>
                <li className="nav-item flex-fill">
                  <Link to="/coprative_act" className="nav-link">
                    {HeaderPage.CoprativeText}
                  </Link>
                </li>
                <li className="nav-item flex-fill">
                  <Link to="/staff" className="nav-link">
                    {HeaderPage.StaffText}
                  </Link>
                </li>
                <li className="nav-item flex-fill">
                  <Link to="/facilites" className="nav-link">
                    {HeaderPage.FacilitieText}
                  </Link>
                </li>
                <li className="nav-item flex-fill">
                  <Link to="/important" className="nav-link">
                    {" "}
                    {HeaderPage.ImportantLinkText}
                  </Link>
                </li>
                <li className="nav-item flex-fill">
                  <Link to="/contact_Us" className="nav-link">
                    {HeaderPage.ContactUsText}
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;

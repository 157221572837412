import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from "react";

import { SyllabusPage } from "../Common jquery/webkitText";

import { handleError } from "../Common jquery/CommonJquery.js";
import {
  server_post_data,
  get_syllabus,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";

const Syllabus = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [getSyllabus, setgetSyllabus] = useState([]);

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_syllabus, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setgetSyllabus(Response.data.message);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  function handleClick(pdfLink) {
    window.open(pdfLink, "_blank");
  }
  console.log(getSyllabus);

  return (
    <>
      <Header />
      <div className="SyllabusContainer">
        <div className={showLoaderAdmin ? "loading_website" : ""}></div>
        <div className="faq-title text-center pb-3 syllbs">
          <h2>{SyllabusPage.SyllabusTxt}</h2>
        </div>
        <div className="syllabusContent">
          <div className="container sllcont">
            {getSyllabus.map((item, index) => (
              <React.Fragment key={index}>
                {/* Section */}
                <div
                  className={`row align-items-center how-it-works d-flex ${
                    index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                  }`}
                >
                  <div className="col-2">
                    <button
                      className="dd"
                      style={{
                        border: "none",
                        backgroundColor: "rgba(0, 0, 0, 0)",
                      }}
                      onClick={() => {
                        if (item.pdf_url.length > 6) {
                          handleClick(APL_LINK + "media/" + item.pdf_url);
                        }
                      }}
                    >
                      <h5>{item.title}</h5>
                    </button>
                  </div>
                  <div
                    className={`col-2 text-center  d-inline-flex justify-content-center align-items-center`}
                  >
                    {/* <div className="circle font-weight-bold">{item.id}</div> */}
                  </div>
                </div>
                {/* Path */}
                {index !== getSyllabus.length - 1 && (
                  <div className="row timeline">
                    <div className="col-2">
                      <div
                        className={`corner ${
                          index % 2 === 0 ? "top-right" : "right-bottom"
                        }`}
                      ></div>
                    </div>
                    <div className="col-8">
                      <hr />
                    </div>
                    <div className="col-2">
                      <div
                        className={`corner ${
                          index % 2 === 0 ? "left-bottom" : "top-left"
                        }`}
                      ></div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Syllabus;

import Header from "./Header";
import Footer from "./Footer";
import { importantPage } from "../Common jquery/webkitText";
import { useState, useEffect } from "react";
import { handleError } from "../Common jquery/CommonJquery.js";
import {
  server_post_data,
  get_important_links,
} from "../ServiceConnection/serviceconnection.js";
const Important = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [getimplink, setgetimplink] = useState([]);

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_important_links, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setgetimplink(Response.data.message);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
    console.log(getimplink);
  };

  return (
    <>
      <Header />
      <div clasName="sylabus">
        <div className={showLoaderAdmin ? "loading_website" : ""}></div>

        <section className="faq-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="faq-title text-center pb-3">
                  <h2>{importantPage.importantLinktext}</h2>
                </div>
              </div>
              <div className="col-md-6 offset-md-3">
                <div className="faq" id="accordion">
                  {!getimplink
                    ? []
                    : getimplink.map((message, index) => (
                        <div className="card" key={index}>
                          <div className="card-header" id="faqHeading-1">
                            <div className="mb-0">
                              <h5
                                className="faq-title"
                                data-toggle="collapse"
                                data-target="#faqCollapse-1"
                                data-aria-expanded="true"
                                data-aria-controls="faqCollapse-1"
                              >
                                <span className="badge"></span>
                                <a
                                  href={message.ImpImageLink}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {message.title}
                                </a>
                              </h5>
                            </div>
                          </div>
                        </div>
                      ))}
                  {/* <div className="card">
                      <div className="card-header" id="faqHeading-2">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-2"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-2"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href={getimplink.ImpImageLink}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {getimplink.title}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div> */}
                  {/* <div className="card">
                      <div className="card-header" id="faqHeading-3">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-3"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-3"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href={getimplink.ImpImageLink}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {getimplink.title}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div> */}
                  {/* <div className="card">
                      <div className="card-header" id="faqHeading-4">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-4"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-4"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://cooperatives.mp.gov.in/hi"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt4}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-5">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-5"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-5"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://apexbank.in/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt5}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-6">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-6"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-6"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://mpmarkfed.mp.gov.in/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt6}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="http://www.mpscuonline.in/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt7}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="http://www.mfpfederation.org/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt8}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://www.mpmarkfedmis.com/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt9}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://sanchidairy.com/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt10}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://mpawassangh.in/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt11}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://www.iffco.in/en/corporate"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt12}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://amul.com/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt13}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="faqHeading-7">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-target="#faqCollapse-7"
                            data-aria-expanded="false"
                            data-aria-controls="faqCollapse-7"
                          >
                            <span className="badge"></span>{" "}
                            <a
                              href="https://www.kribhco.net/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {importantPage.impotentlinkHtt14}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Important;

import { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Swiper from "swiper";
import "/node_modules/swiper/swiper";
import News from "./News";
import IndexFacility from "./IndexFacility";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin";

import homeSLider1 from "../assets/homeslider1.webp";
import homeSLider2 from "../assets//homeslider2.webp";
import homeSLider3 from "../assets/homeslider3.webp";
import homeSLider4 from "../assets/homeslider4.webp";
import { IndexHomePage } from "../Common jquery/webkitText";
import {
  server_post_data,
  get_all_imagelink,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../Common jquery/CommonJquery.js";
const IndexHome = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const swiperRef = useRef(null);

  const images = [homeSLider1, homeSLider2, homeSLider3, homeSLider4];

  const [newsList, setNewsList] = useState([]);
  const [NewsListAdd, setNewsListAdd] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_imagelink, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setNewsList(Response.data.message.data_imagelink);
          setNewsListAdd(Response.data.message.data_link_image);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  //img side show facilitie img and home side show
  useEffect(() => {
    const homeSliderInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % newsList.length);
    }, 4000);

    return () => {
      clearInterval(homeSliderInterval);
    };
  }, [newsList.length]);

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = new Swiper(swiperRef.current, {
        spaceBetween: 30,
        effect: "fade",
        loop: true,
        mousewheel: {
          invert: false,
        },
        pagination: {
          el: ".blog-slider__pagination",
          clickable: true,
        },
      });

      return () => {
        swiper.destroy();
      };
    }
  }, []);

  return (
    <div className="container-fluid cont">
      <Header />
      <div className="row m-0">
        {/*--heroSaction-2*/}
        <div className="heroS2">
          <div className="imgCarausal">
            {newsList.length > 0
              ? newsList.map((image, index) => (
                  <img
                    key={index}
                    src={APL_LINK + NewsListAdd + image.images_url}
                    alt={`Image ${index}`}
                    className={
                      index === currentImageIndex ? "visible" : "hidden"
                    }
                  />
                ))
              : images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index}`}
                    className={
                      index === currentImageIndex ? "visible" : "hidden"
                    }
                  />
                ))}
          </div>
          <div className="centerSection Ne ">
            <div className="ourMission">
              <h2>{IndexHomePage.OurVissionText}</h2>
              <p>{IndexHomePage.ourMission}</p>
            </div>
            <div className="missionImage">
              <div className="newsSection">
                <h2>{IndexHomePage.LetestNews}</h2>

                <News />
              </div>
            </div>
          </div>
          <div className="hero-slider">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                className={index === currentImageIndex ? "visible" : "hidden"}
              />
            ))}
          </div>
          {/* Our Vision Section */}
          <div className="centerSection">
            <div className="visionImage">
              <img
                src="https://img.freepik.com/premium-photo/our-vision-concept-business-technology-internet_220873-13778.jpg"
                alt="Vision Image"
              />
            </div>
            <div className="ourVision">
              <h2>{IndexHomePage.OurmissionText}</h2>
              <p>{IndexHomePage.contentOurVision}</p>
            </div>
          </div>
          <div className="hero-slider">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                className={index === currentImageIndex ? "visible" : "hidden"}
              />
            ))}
          </div>
          <div className="News_Tranng">
            <div className="FacilitesText">
              <h1>{IndexHomePage.FacilitieTxt}</h1>
            </div>
            <IndexFacility />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndexHome;

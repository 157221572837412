import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { AboutPage, StaffPage } from "../Common jquery/webkitText";

import diractorImg from "../assets/AbouTUsdirector.jpg";
import aboutusimage from "../assets/show_ablout.jpeg";
import { Link } from "react-router-dom";
import "../css/About.css";
const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="AboutContainer">
        <div className="AboutContent">
          <section className="about-section">
            <div className="container">
              <div className="DiratorName mb-10">
                <div className="row m-0">
                  <div className="DiractorSection">
                    <div className="team-member Diractorimg">
                      <img src={diractorImg} alt="Dr. Ajay Sharma" />
                      <h3>{StaffPage.FacultiNametxt}</h3>
                      <p className="role-2">{StaffPage.Facultirole}</p>
                    </div>

                    <div className="articleDiractor">
                      <div>
                        <h4>{StaffPage.title}</h4>
                        <p>{StaffPage.diractorContent}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="articleDiractor2 articleDiractor2_dddd">
                  <div>
                    <p>{StaffPage.diractorContent3}</p>
                    <p>{StaffPage.diractorContent2}</p>
                  </div>
                </div>
              </div>
              <div className="DiratorNameContant">
                {" "}
                <div className="row m-0 mt-10 flex-row-reverse">
                  <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2 contentAboutt">
                    <div className="inner-column">
                      <div className="sec-title">
                        <h1 className="title">{AboutPage.aboutText} </h1>
                      </div>
                      <div className="text TeXtPG">
                        <p>{AboutPage.content}</p>
                      </div>
                      <div className="btn-box">
                        <Link
                          to="/contact_Us"
                          className="theme-btn btn-style-one"
                        >
                          {AboutPage.ContactUsText}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="image-column col-lg-6 col-md-12 col-sm-12">
                    <div className="inner-column wow fadeInLeft">
                      <figure className="image-1">
                        <img src={aboutusimage} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>{" "}
              </div>

              {/* <div className="textTeXtPG2">
                <p>{AboutPage.content2}</p>
              </div> */}
              <div className="textTeXtPG3">
                <p>{AboutPage.content3}</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;

import "./App.css";
import "./css/Header.css";
import "./css/Footer.css";
import "./css/About.css";
import "./css/Facilitie.css";
import "./css/ContactUs.css";
import "./css/staff.css";
import "./css/Important.css";
import "./css/Syllabus.css";

import { Route, Routes, BrowserRouter } from "react-router-dom";
import IndexHome from "./components/IndexHome";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./css/IndexHome.css";
import AboutUs from "./components/AboutUs";
import Facilites from "./components/Facilites";
import ContactUs from "./components/ContactUs";
import Staff from "./components/staff";
import Important from "./components/Important";
import Syllabus from "./components/Syllabus";
import CoprativeA from "./components/coprativeA";
import Programcommite from "./components/Programcommite";
import Ourmissition from "./components/ourmissition";

function App() {
  return (
    <div className="container-fluid cont">
      <BrowserRouter>
        <Routes>
          {/*-------Website------*/}
          {/*Header*/}
          <Route path="header" element={<Header />} />
          {/*Landing Page */}
          <Route path="/" element={<IndexHome />} />
          {/*AboutUs Page*/}
          <Route path="/about_us" element={<AboutUs />} />
          {/*Facilites Page*/}
          <Route path="/facilites" element={<Facilites />} />
          {/*Contact Us Page*/}
          <Route path="/contact_Us" element={<ContactUs />} />
          {/*Staff Us Page*/}
          <Route path="/staff" element={<Staff />} />
          {/*Important Us Page*/}
          <Route path="/important" element={<Important />} />
          {/*Syllabus Us Page*/}
          <Route path="/syllabus" element={<Syllabus />} />
          {/*CoprativeAct Us Page*/}
          <Route path="/coprative_act" element={<CoprativeA />} />
          {/*CoprativeAct Us Page*/}
          <Route path="/our_mission" element={<Ourmissition />} />
          {/*Footer Page*/}
          <Route path="/footer" element={<Footer />} />
          <Route path="/rti_page" element={<Programcommite />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import { useState, useEffect } from "react";
import { handleError } from "../Common jquery/CommonJquery.js";
import {
  server_post_data,
  get_news,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";

const News = () => {
  const [Newsmessages, setnewmessages] = useState();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_news, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setnewmessages(Response.data.message);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
    console.log(Newsmessages);
  };
  function handleClick(pdfLink) {
    window.open(pdfLink, "_blank");
    console.log(handleClick);
  }
  return (
    <div>
      <div className={showLoaderAdmin ? "loading_website" : ""}></div>
      <div className="d-flex">
        <marquee behavior="scroll" direction="up" scrollamount="2" height="150">
          {!Newsmessages
            ? []
            : Newsmessages.map((message, index) => (
                <div key={index}>
                  <button
                    onClick={() => {
                      if (message.Img_url.length > 6) {
                        handleClick(APL_LINK + "media/" + message.Img_url);
                      }
                    }}
                    style={{ background: "transparent", border: "none" }}
                  >
                    <p>{message.news_name}</p>
                  </button>
                </div>
              ))}
        </marquee>
      </div>
    </div>
  );
};

export default News;

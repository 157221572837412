import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";

import complab1 from "../assets/comlab-img1.jpg";
import complab2 from "../assets/comlab-img2.jpg";
import libimg1 from "../assets/lib-img1.jpg";
import libimg2 from "../assets/lib-img2.jpg";
import adminFecImg1 from "../assets/adminfes-1.jpg";
import adminFecImg2 from "../assets/adminfes-2.jpg";
import adminFecImg3 from "../assets/adminfes-3.jpg";
import adminFecImg4 from "../assets/adminfes-4.jpg";
import adminFecImg5 from "../assets/adminfes-5.jpg";
import adminFecImg6 from "../assets/adminfes-6.jpg";
import adminFecImg7 from "../assets/adminfes-7.jpg";
import adminFecImg8 from "../assets/adminfes-8.jpg";
import adminFecImg9 from "../assets/adminfes-9.jpg";
import adminFecImg10 from "../assets/adminfes-10.jpg";
import adminFecImg11 from "../assets/adminfes-11.jpg";
import { FacilitiePage } from "../Common jquery/webkitText";
import { handleError } from "../Common jquery/CommonJquery.js";
import {
  server_post_data,
  get_facility,
} from "../ServiceConnection/serviceconnection.js";
//facilite heading
const Facilites = () => {
  const [getfaculty, setfaculty] = useState();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  // services section

  //marque images
  const imageFacilitySection = {
    images: [
      complab1,
      complab2,
      libimg1,
      libimg2,
      adminFecImg1,
      adminFecImg2,
      adminFecImg3,
      adminFecImg4,
      adminFecImg5,
      adminFecImg6,
      adminFecImg7,
      adminFecImg8,
      adminFecImg9,
      adminFecImg10,
      adminFecImg11,
    ],
  };

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);

  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_facility, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message.title_name);
        } else {
          setfaculty(Response.data.message);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  //marqueee stop

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  console.log(getfaculty);
  return (
    <>
      <Header />
      <div className="FaciiteContainer">
        <div className={showLoaderAdmin ? "loading_website" : ""}></div>
        <section className="section-services">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-10 col-lg-8">
                <div className="header-section">
                  <h2 className="title TitleFec">
                    {FacilitiePage.ComputerLabTxt}
                  </h2>
                  {/* <p className="description">{FacilitiePage.facilitediscrib}</p> */}
                </div>
              </div>
            </div>
            <div className="row m-0">
              {!getfaculty
                ? []
                : getfaculty.map((fac, index) => (
                    <div className="col-md-6 col-lg-4">
                      <div className="single-service">
                        <div className="content">
                          {/* {services_text.map((service, index) => (
                            <span className="icon">
                              <div className={service.icon} key={index}>
                                <img src={service.imgSrc} alt={service.title} />
                              </div>
                            </span>
                          ))} */}
                          <h3 className="title">{fac.title}</h3>
                          <p className="description">{fac.description}</p>
                        </div>
                        <span className="circle-before"></span>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </section>
        <div className="ImageFacilitee">
          <div
            className="marquee"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className="marquee-content"
              style={{ animationPlayState: isPaused ? "paused" : "running" }}
            >
              {imageFacilitySection.images.map((image, index) => (
                <div className="marquee-item" key={index}>
                  <img src={image} alt={`facility-image-${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Facilites;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { coprativePage } from "../Common jquery/webkitText";
import { handleError } from "../Common jquery/CommonJquery.js";
import { useState, useEffect } from "react";
import {
  server_post_data,
  get_cooprative_acts,
} from "../ServiceConnection/serviceconnection.js";

const CoprativeA = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [getActs, setgetActs] = useState([]);

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_cooprative_acts, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setgetActs(Response.data.message);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
    console.log(getActs);
  };

  return (
    <div>
      <Header />
      <div>
        <div clasName="sylabus">
          <div className={showLoaderAdmin ? "loading_website" : ""}></div>
          <section className="faq-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 offset-md-3">
                  <div className="faq-title text-center pb-3">
                    <h2>{coprativePage.copHeading}</h2>
                  </div>
                </div>
                <div className="col-md-6 offset-md-3">
                  <div className="faq" id="accordion">
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>

                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle1}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    {!getActs
                      ? []
                      : getActs.map((act, index) => (
                          <div className="card" key={index}>
                            <div className="card-header">
                              <div className="mb-0">
                                <h5
                                  className="faq-title"
                                  data-toggle="collapse"
                                  data-aria-expanded="true"
                                >
                                  <span className="badge"></span>
                                  <a href={act.pdf_url}>{act.coapname}</a>
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                    {/* <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle3}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle4}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle5}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle6}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle7}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle8}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle9}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle10}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle11}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle12}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle13}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle14}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle15}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <div className="mb-0">
                          <h5
                            className="faq-title"
                            data-toggle="collapse"
                            data-aria-expanded="true"
                          >
                            <span className="badge"></span>
                            <a href="#" onclick="event.preventDefault();">
                              {coprativePage.coptitle16}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CoprativeA;

import Header from "./Header";
import { useEffect, useState } from "react";
import GuestFaculti from "./guestFaculti";
import Footer from "./Footer";
import diractorImg from "../assets/DirectorImg.png";

import FacultiDeatailts from "./FacultiDeatailts";

import { StaffPage, staffData } from "../Common jquery/webkitText";
import { handleError } from "../Common jquery/CommonJquery.js";
import {
  server_post_data,
  get_staff_data,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import $ from "jquery";
const Staff = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [staffData, setstaffData] = useState();
  const [staffDataimage_path, setstaffDataimage_path] = useState();
  const [isPaused, setIsPaused] = useState(false);
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_staff_data, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setstaffData(Response.data.message);
          setstaffDataimage_path(Response.data.image_path);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  //marqueee stop

  $(document).ready(function () {
    $(".staff-image").hover(
      function () {
        var index = $(this).data("index");
        $(".member-info").each(function (i) {
          if (i == index) {
            $(this).css({ opacity: 1, visibility: "visible" });
          } else {
            $(this).css({ opacity: 0, visibility: "hidden" });
          }
        });
      },
      function () {
        $(".member-info").css({ opacity: 0, visibility: "hidden" });
      }
    );
  });

  //hover img content show
  $(document).ready(function () {
    $(".FaculTi").hover(
      function () {
        $(this).find(".member-info").css({ opacity: 1, visibility: "visible" });
      },
      function () {
        $(this).find(".member-info").css({ opacity: 0, visibility: "hidden" });
      }
    );
  });

  return (
    <>
      <Header />
      <div className="StaffContainer">
        <div className={showLoaderAdmin ? "loading_website" : ""}></div>

        <div className="rows m-0">
          <div className="FaultiText">
            <h3>{StaffPage.facultiDeatailText}</h3>
          </div>
        </div>
        <FacultiDeatailts />
        <div className="teamMemsAdministr">
          {" "}
          <div
            className="guesTbleHeading"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h2>{StaffPage.tableName}</h2>
          </div>
          <GuestFaculti />
        </div>
        <section>
          <div className="AdminFacult">
            <h3>{StaffPage.AdminStaffTxt}</h3>
          </div>
          <div className="container-fluid">
            <div className="SatffImgss">
              <div
                className="marquee"
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => setIsPaused(false)}
              >
                <div className="marquee-content">
                  <div className="member-details">
                    {!staffData
                      ? []
                      : staffData.map((staff, index) => (
                          <div className="team-member Diractorimg" key={index}>
                            <img
                              src={
                                APL_LINK + staffDataimage_path + staff.Image_url
                              }
                              alt={staff.name}
                            />
                            <h3>{staff.name}</h3>
                            <p className="role-2">{staff.responsibility}</p>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Staff;

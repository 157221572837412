import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Programcomt, data } from "../Common jquery/webkitText";
import { handleError } from "../Common jquery/CommonJquery.js";
import { useState, useEffect } from "react";
import {
  server_post_data,
  get_programe_addvisory,
} from "../ServiceConnection/serviceconnection.js";

const Programcommite = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [getprogram, setgetprogram] = useState([]);

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get(flag, call_id);
  }, []);
  const master_data_get = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_programe_addvisory, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setgetprogram(Response.data.message);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  console.log(getprogram);
  return (
    <div>
      <Header />
      <div className="TableContainerCommitee">
        <div className={showLoaderAdmin ? "loading_website" : ""}></div>
        <div className="header-section  programHdr">
          <div className="proh1">
            {" "}
            <h2 className="title TitleFec">{Programcomt.programHeader}</h2>
          </div>
        </div>
        <div className="table-responsive tbbbll">
          <table className="table table-hover">
            <thead className="thead">
              <tr>
                <th>{Programcomt.tableheading1}</th>
                <th>{Programcomt.tableheading2}</th>
                <th>{Programcomt.tableheading3}</th>
              </tr>
            </thead>
            <tbody>
              {getprogram.map((item, index) => (
                <tr key={index}>
                  <td>{item.responsibility}</td>
                  <td>{item.organisation_name}</td>
                  <td>{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Programcommite;

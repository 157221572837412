import React, { useState } from "react";

import Header from "./Header";
import * as XLSX from "xlsx";
const Ourmissition = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 9;
  // Sample data
  const data = [
    { id: 1, name: "John", age: 25 },
    { id: 2, name: "Jane", age: 30 },
    { id: 3, name: "Doe", age: 35 },
    { id: 4, name: "Doe", age: 35 },
    { id: 5, name: "Doe", age: 35 },
    { id: 6, name: "Doe", age: 35 },
    { id: 7, name: "Doe", age: 35 },
    { id: 8, name: "Doe", age: 25 },
    { id: 9, name: "Doe", age: 35 },
    { id: 10, name: "Doe", age: 35 },
    { id: 11, name: "Doe", age: 35 },
    { id: 12, name: "Doe", age: 45 },
    { id: 1, name: "John", age: 25 },
    { id: 2, name: "Jane", age: 30 },
    { id: 3, name: "Doe", age: 35 },
    { id: 4, name: "Doe", age: 35 },
    { id: 5, name: "Doe", age: 35 },
    { id: 6, name: "Doe", age: 35 },
    { id: 7, name: "Doe", age: 35 },
    { id: 8, name: "Doe", age: 25 },
    { id: 9, name: "Doe", age: 35 },
    { id: 10, name: "Doe", age: 35 },
    { id: 11, name: "Doe", age: 35 },
    { id: 12, name: "Doe", age: 45 },
    { id: 1, name: "John", age: 25 },
    { id: 2, name: "Jane", age: 30 },
    { id: 3, name: "Doe", age: 35 },
    { id: 4, name: "Doe", age: 35 },
    { id: 5, name: "Doe", age: 35 },
    { id: 6, name: "Doe", age: 35 },
    { id: 7, name: "Doe", age: 35 },
    { id: 8, name: "Doe", age: 25 },
    { id: 9, name: "Doe", age: 35 },
    { id: 10, name: "Doe", age: 35 },
    { id: 11, name: "abhay", age: 35 },
    { id: 12, name: "Doe", age: 45 },
    { id: 13, name: "John", age: 25 },
    { id: 14, name: "Jane", age: 30 },
    { id: 15, name: "Doe", age: 35 },
  ];

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.age.toString().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const highlightSearchTerm = (text, term) => {
    if (!term) return text;
    const regex = new RegExp(`(${term})`, "gi");
    return text
      .split(regex)
      .map((part, index) =>
        part.toLowerCase() === term.toLowerCase() ? (
          <span key={index}>{part}</span>
        ) : (
          part
        )
      );
  };
  // Download CSV
  const downloadCSV = () => {
    const header = ["ID", "Name", "Age"].join(",");
    const csv = [
      header,
      ...currentItems.map((item) => [item.id, item.name, item.age].join(",")),
    ].join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  // Download Excel
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    let allData = [];
    for (let i = 1; i <= totalPages; i++) {
      paginate(i);
      const currentData = data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.age.toString().includes(searchTerm.toLowerCase())
      );
      allData = [...allData, ...currentData];
    }
    const worksheet = XLSX.utils.json_to_sheet(allData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "All Data");
    XLSX.writeFile(workbook, "all_data.xlsx");
  };
  return (
    <div>
      <Header />

      <div className="container">
        <div className="d-flex">
          {" "}
          <div
            className="btn-group mr-2"
            role="group"
            aria-label="Download and Save"
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={downloadCSV}
            >
              Download CSV
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={downloadExcel}
            >
              Download Excel
            </button>
          </div>
        </div>
        <div className="sideBarrSearch">
          <div className="mb-3 searchbar sideBarrSearch2 ">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name or Age"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Age</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{highlightSearchTerm(item.name, searchTerm)}</td>
                <td>{item.age}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ul className="pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <li
              key={i}
              className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
            >
              <button onClick={() => paginate(i + 1)} className="page-link">
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Ourmissition;
